import React, { useLayoutEffect, useRef } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import OurFeatures from "../components/OurFeatures/OurFeatures";
import Cover from "../components/sections/TechnicalExperties/Cover/Cover";
import { TechnicalExpertiesData, TechnicalExpertiesCTAsData } from "../../data";
import CTAsSection from "../components/CTAsSection/CTAsSection";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { Helmet } from "react-helmet";

gsap.registerPlugin(ScrollTrigger);

const TechnicalExperties = () => {
  return (
    <>
      <Helmet>
        <title>Technical expertise</title>
        <meta
          name="description"
          content="endshift is a solution of the last resort. It helps enterprises, small to large businesses, and startups to scale and remove any technological congestion."
        />
        <link rel="canonical" href="https://endshift.io/" />
      </Helmet>
      <Header />
      <main>
        <Cover />
        <div>
          <OurFeatures featuresData={TechnicalExpertiesData} />
        </div>
        <CTAsSection data={TechnicalExpertiesCTAsData} />
      </main>
      <Footer />
    </>
  );
};

export default TechnicalExperties;
